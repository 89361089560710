import Rails from '@rails/ujs';

/**
 * Preload all images
 */
require.context('images', true);

/**
 * Preload all fonts
 */
require.context('fonts', true);

/**
 * Activate only data-method attribute for links
 */
Rails.delegate(document, Rails.linkClickSelector, 'click', Rails.handleMethod);
